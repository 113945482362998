import React from 'react';
import { Icon } from '@chakra-ui/icons';
import {
  MdManageAccounts,
  MdPeopleAlt,
  MdSchool,
  MdSettings,
} from 'react-icons/md';


const SchoolProfile = React.lazy(() => import('views/settings/schoolProfile'))
const FeeStructure = React.lazy(() => import('views/settings/feeStructure'))
const Branches = React.lazy(() => import('views/settings/branches'))
const Enquiry = React.lazy(() => import('views/admin/admissions/enquiry'))
const FeeType = React.lazy(() => import('views/settings/feeType'))
const ManageEmployee = React.lazy(() => import('views/admin/manageEmployee'))
const Designation = React.lazy(() => import('views/settings/designation'))
const ManageRoles = React.lazy(() => import('views/settings/manageRoles'))
const ExpenseTypes = React.lazy(() => import('views/Accounts/components/expenseTypes'))
const Subject = React.lazy(() => import('views/settings/subjects'))
const UserProfile = React.lazy(() => import('views/settings/userProfile'))
const DiscountType = React.lazy(() => import('views/settings/discountTypes'))
const Admission = React.lazy(() => import('views/admin/admissions/admission'));
const Students = React.lazy(() => import('views/Students'))
const Admin = React.lazy(() => import('views/admin/dashboard/Components/AdminDashboard'))
const Teacher = React.lazy(() => import('views/admin/dashboard/Components/TeacherDashboard'))
const Class = React.lazy(() => import('views/admin/classes/classes'))
const StudentBulkUpload = React.lazy(() => import('views/bulkUpload/components/StudentBulkUpload'))
const StudentDetailsAudit = React.lazy(() => import('views/bulkUpload/components/StudentDetailsAudit'))
const UserRoles = React.lazy(() => import('views/settings/userRoleManagement'))
const ClassMap = React.lazy(() => import('views/admin/classes/classes/components/ClassMap'))
const FeeTypeMap = React.lazy(() => import('views/settings/feeType/components/FeeTypeMap'))
const SubjectMap = React.lazy(() => import('views/settings/subjects/components/SubjectMap'))
const ClassSubjectMap = React.lazy(() => import('views/admin/classes/classSubjectMap'))
const EmployeeMap = React.lazy(() => import('views/admin/manageEmployee/components/EmployeeMap'))
const Section = React.lazy(() => import('views/admin/classes/sections'))

const routes: any = [

  // --- Dashboards ---
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <Icon as={MdSchool} width="20px" height="20px" color="inherit" />,
    collapse: true,
    privilege: 'Dashboard',
    id: 'dashboard_1',
    items: [
      {
        name: 'Admin Dashboard',
        layout: '/admin',
        path: '/dashboard/admindashboard',
        secondary: true,
        component: <Admin />,
        privilege: 'Admin Dashboard',
        id: 'dashboard_2',
      },
      {
        name: 'Teacher Dashboard',
        layout: '/admin',
        path: '/dashboard/teacherdashboard',
        secondary: true,
        component: <Teacher />,
        privilege: 'Teacher Dashboard',
        id: 'dashboard_3',
      },
    ],
  },

  // --- Settings ---
  {
    name: 'Settings',
    path: '/settings',
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    collapse: true,
    privilege: 'Settings',
    id: 'settings_1',
    items: [
      {
        name: 'Institute Profile',
        layout: '/admin',
        path: '/settings/instituteprofile',
        secondary: true,
        component: <SchoolProfile />,
        privilege: 'View Institute Profile',
        id: 'settings_2',

      },
      {
        name: 'Branches',
        layout: '/admin',
        path: '/settings/branches',
        secondary: true,
        component: <Branches />,
        privilege: 'View Branches',
        instSetting: true,
        id: 'settings_3',
      },
    ],
  },

  // --- Branch Settings ---
  {
    name: 'Branch Settings',
    path: '/branchsettings',
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    collapse: true,
    privilege: 'Branch Settings',
    id: 'branchsettings_1',
    items: [
      {
        name: 'Classes',
        layout: '/admin',
        path: '/branchsettings/classes',
        secondary: true,
        component: <Class />,
        privilege: 'View Classes',
        id: 'branchsettings_2',
      },

      {
        name: 'Manage Classes',
        layout: '/admin',
        path: '/branchsettings/manageclasses',
        secondary: true,
        component: <ClassMap />,
        privilege: 'Manage Classes',
        id: 'branchsettings_3',
      },

      {
        name: 'Fee Types',
        layout: '/admin',
        path: '/branchsettings/feetype',
        secondary: true,
        component: <FeeType />,
        privilege: 'View Fee Types',
        id: 'branchsettings_4',
      },

      {
        name: 'Manage Fee Types',
        layout: '/admin',
        path: '/branchsettings/managefeetypes',
        secondary: true,
        component: <FeeTypeMap />,
        privilege: 'Manage Fee Types',
        id: 'branchsettings_5',
      },

      {
        name: 'Fee Structure',
        layout: '/admin',
        path: '/branchsettings/feestructure',
        secondary: true,
        component: <FeeStructure />,
        privilege: 'View Fee Structure',
        id: 'branchsettings_6',
      },

      {
        name: 'Discount Types',
        layout: '/admin',
        path: '/branchsettings/discounttype',
        secondary: true,
        component: <DiscountType />,
        privilege: 'View Discount Types',
        id: 'branchsettings_7',
      },

      {
        name: 'Expense Types',
        layout: '/admin',
        path: '/branchsettings/expensetype',
        secondary: true,
        component: <ExpenseTypes />,
        privilege: 'View Expenses Types',
        id: 'branchsettings_8',
      },

      {
        name: 'Subjects',
        layout: '/admin',
        path: '/branchsettings/subjects',
        secondary: true,
        component: <Subject />,
        privilege: 'View Subjects',
        id: 'branchsettings_9',
      },

      {
        name: 'Manage Subjects',
        layout: '/admin',
        path: '/branchsettings/managesubjects',
        secondary: true,
        component: <SubjectMap />,
        privilege: 'Manage Subjects',
        id: 'branchsettings_10',
      },

      {
        name: 'Manage Class Subjects',
        layout: '/admin',
        path: '/branchsettings/classsubjects',
        secondary: true,
        component: <ClassSubjectMap />,
        privilege: 'Manage Class Subjects',
        id: 'branchsettings_11',
      },

      {
        name: 'Employee Designations',
        layout: '/admin',
        path: '/branchsettings/designation',
        secondary: true,
        component: <Designation />,
        privilege: 'View Designations',
        id: 'branchsettings_12',
      },

      {
        name: 'Roles',
        layout: '/admin',
        path: '/branchsettings/roles',
        secondary: true,
        component: <ManageRoles />,
        privilege: 'View Roles',
        id: 'branchsettings_13',
      },

      {
        name: 'Sections',
        layout: '/admin',
        path: '/branchsettings/sections',
        secondary: true,
        component: <Section />,
        privilege: 'View Sections',
        id: 'branchsettings_14',
      },

      {
        name: 'User Profile',
        layout: '/admin',
        path: '/branchsettings/userprofile',
        secondary: true,
        component: <UserProfile />,
        privilege: 'User Profile',
        id: 'branchsettings_15',
      },

    ],
  },

  // --- Admissions ---
  {
    name: 'Admissions',
    path: '/admissions',
    icon: <Icon as={MdSchool} width="20px" height="20px" color="inherit" />,
    collapse: true,
    privilege: 'Admissions',
    id: 'admissions_1',
    items: [
      {
        name: 'Enquiry',
        layout: '/admin',
        path: '/admissions/enquiry',
        secondary: true,
        component: <Enquiry />,
        privilege: 'View Enquiries',
        id: 'admissions_2',

      },
      {
        name: 'Admission',
        layout: '/admin',
        path: '/admissions/admission',
        secondary: true,
        component: <Admission />,
        privilege: 'View Admissions',
        id: 'admissions_3',
      },
    ],
  },

  // --- Manage Students ---
  {
    name: 'Manage Students',
    path: '/managestudents',
    icon: <Icon as={MdPeopleAlt} width="20px" height="20px" color="inherit" />,
    collapse: true,
    privilege: 'Manage Students',
    id: 'students_1',
    items: [
      {
        name: 'Students',
        layout: '/admin',
        path: '/managestudents',
        component: <Students />,
        secondary: true,
        privilege: 'View Students',
        id: 'students_2',
      }]
  },
  // --Manage Staff ---
  {
    name: 'Manage Employee',
    path: '/employee',
    icon: <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />,
    collapse: true,
    privilege: 'Manage Employee',
    id: 'employee_1',
    items: [
      {
        name: 'Employee',
        layout: '/admin',
        path: '/employee/manageemployee',
        component: <ManageEmployee />,
        secondary: true,
        privilege: 'View Employees',
        id: 'employee_2',
      },
      {
        name: 'Employee Mappings',
        layout: '/admin',
        path: '/employee/employeemappings',
        component: <EmployeeMap />,
        secondary: true,
        privilege: 'Employee Mappings',
        id: 'employee_3',
      },
    ]
  },

  // --Manage User ---
  {
    name: 'Manage Users',
    path: '/manageuser',
    icon: <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />,
    collapse: true,
    privilege: 'Manage User Roles',
    id: 'user_1',
    items: [
      {
        name: 'User Role',
        layout: '/admin',
        path: '/manageuser/userrole',
        component: <UserRoles />,
        secondary: true,
        privilege: 'Users Role',
        id: 'user_2',
      },
    ]
  },

  {
    name: 'Utility',
    path: '/utility',
    icon: <Icon as={MdPeopleAlt} width="20px" height="20px" color="inherit" />,
    collapse: true,
    privilege: 'Utility',
    id: 'utility_1',
    items: [
      {
        name: 'Students Bulk Upload',
        layout: '/admin',
        path: '/utility/studentsbulkupload',
        component: <StudentBulkUpload />,
        secondary: true,
        privilege: 'Students Bulk Upload',
        id: 'utility_2',
      },


      {
        name: 'Bulk Upload History',
        layout: '/admin',
        path: '/utility/bulkuploadhistory',
        component: <StudentDetailsAudit />,
        secondary: true,
        privilege: 'Bulk Upload History',
        id: 'utility_3',
      }

    ]
  },
];

export default routes;
